import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { textColorNames, iconNames } from '../constants';

import styles from './Icon.module.scss';

import Aim from '@/images/icons/aim_24.svg?react';
import Archive from '@/images/icons/archive_24.svg?react';
import ArrowRightIcon from '@/images/icons/arrow-right_24.svg?react';
import ArrowUpLeft from '@/images/icons/arrow-up-left_24.svg?react';
import Board from '@/images/icons/board_24.svg?react';
import Bulb from '@/images/icons/bulb_24.svg?react';
import Calendar from '@/images/icons/calendar_24.svg?react';
import CanvasIcon from '@/images/icons/canvas_24.svg?react';
import Check from '@/images/icons/check_24.svg?react';
import ChevronLeft from '@/images/icons/chevron-left_24.svg?react';
import ChevronRight from '@/images/icons/chevron-right_24.svg?react';
import CircleAlert from '@/images/icons/circle-alert_24.svg?react';
import CirclePlus from '@/images/icons/circle-plus_24.svg?react';
import Clock from '@/images/icons/clock_24.svg?react';
import Close from '@/images/icons/close_24.svg?react';
import CloudDownloadIcon from '@/images/icons/cloud-download_24.svg?react';
import CloudIcon from '@/images/icons/cloud_24.svg?react';
import CloudSolid from '@/images/icons/cloud-solid_24.svg?react';
import CloudUploadIcon from '@/images/icons/cloud-upload_24.svg?react';
import Collection from '@/images/icons/collection_24.svg?react';
import ContextIcon from '@/images/icons/context_24.svg?react';
import Delta from '@/images/icons/delta_24.svg?react';
import DollarSign from '@/images/icons/dollar-sign_24.svg?react';
import DoubleCloud from '@/images/icons/double-cloud_24.svg?react';
import DoubleLink from '@/images/icons/double-link_24.svg?react';
import Drag from '@/images/icons/drag_24.svg?react';
import DragDrop from '@/images/icons/drag-drop_24.svg?react';
import Elevator from '@/images/icons/elevator_24.svg?react';
import EntryBoard from '@/images/icons/entry_board_24.svg?react';
import Folder from '@/images/icons/folder_24.svg?react';
import FolderPlus from '@/images/icons/folder-plus_24.svg?react';
import FolderSolid from '@/images/icons/folder-solid_24.svg?react';
import Globe from '@/images/icons/globe_24.svg?react';
import Grid from '@/images/icons/grid_24.svg?react';
import Layout from '@/images/icons/layout_24.svg?react';
import Like from '@/images/icons/like_24.svg?react';
import List from '@/images/icons/list_24.svg?react';
import Reset from '@/images/icons/reset_24.svg?react';
import Record from '@/images/icons/record_24.svg?react';
import RecordSolid from '@/images/icons/record-solid_24.svg?react';
import Settings from '@/images/icons/settings_24.svg?react';
import Search from '@/images/icons/search_24.svg?react';
import SortDown from '@/images/icons/sort-down_24.svg?react';
import Members from '@/images/icons/members_24.svg?react';
import Info from '@/images/icons/info_24.svg?react';
import Plus from '@/images/icons/plus_24.svg?react';
import Minus from '@/images/icons/minus_24.svg?react';
import Team from '@/images/icons/team_24.svg?react';
import Trash from '@/images/icons/trash_24.svg?react';
import TextTool from '@/images/icons/text-tool_24.svg?react';
import Document from '@/images/icons/document_24.svg?react';
import MultipleChoice from '@/images/icons/multiple-choice_24.svg?react';
import Number from '@/images/icons/number_24.svg?react';
import Orchestration from '@/images/icons/orchestration_24.svg?react';
import Percent from '@/images/icons/percent_24.svg?react';
import PerspectiveBoard from '@/images/icons/perspactive_board_24.svg?react';
import Paragraph from '@/images/icons/paragraph_24.svg?react';
import Presenter from '@/images/icons/presenter_24.svg?react';
import SingleChoice from '@/images/icons/single-choice_24.svg?react';
import Thinking from '@/images/icons/thinking_24.svg?react';
import Workflow from '@/images/icons/workflow_24.svg?react';
import Pointer from '@/images/icons/pointer_24.svg?react';
import Move from '@/images/icons/move_24.svg?react';
import Lineage from '@/images/icons/lineage_24.svg?react';
import LinkIcon from '@/images/icons/link_24.svg?react';
import MultiArrowRightIcon from '@/images/icons/multi-arrow-right_24.svg?react';
import MindMapIcon from '@/images/icons/mindmap_24.svg?react';
import SidebarExpandedIcon from '@/images/icons/sidebar-expanded_24.svg?react';
import SidebarContractedIcon from '@/images/icons/sidebar-contracted_24.svg?react';
import Spreadsheet from '@/images/icons/spreadsheet_24.svg?react';
import FilterIcon from '@/images/icons/filter_24.svg?react';
import User from '@/images/icons/user_24.svg?react';
import Writer from '@/images/icons/writer_24.svg?react';
import WorkbinBoard from '@/images/icons/workbin_board_24.svg?react';
import WorkflowBoard from '@/images/icons/workflow_board_24.svg?react';
import WorkspacesIcon from '@/images/icons/workspaces_24.svg?react';

export default function Icon({
    name = iconNames.settings,
    size = 24,
    color = textColorNames.darkPrimary,
}) {
    const iconClassNames = classNames(styles.icon, styles[color]);
    const iconFillClassNames = classNames(styles.icon, styles.fill, styles[color]);
    const sizingStyle = {
        height: size,
        width: size,
    };

    switch (name) {
        case iconNames.aim:
            return <Aim className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.archive:
            return <Archive className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.arrowRight:
            return (
                <ArrowRightIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.arrowUpLeft:
            return (
                <ArrowUpLeft className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.board:
            return <Board className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.bulb:
            return <Bulb className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.calendar:
            return (
                <Calendar className={iconFillClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.canvas:
            return (
                <CanvasIcon className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.check:
            return <Check className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.chevronLeft:
            return (
                <ChevronLeft className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.chevronRight:
            return (
                <ChevronRight className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.circleAlert:
            return (
                <CircleAlert className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.circlePlus:
            return (
                <CirclePlus className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.clock:
            return <Clock className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.close:
            return <Close className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.cloudDownload:
            return (
                <CloudDownloadIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.cloud:
            return <CloudIcon className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.cloudSolid:
            return (
                <CloudSolid
                    className={iconFillClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.cloudUpload:
            return (
                <CloudUploadIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.collection:
            return (
                <Collection className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.context:
            return (
                <ContextIcon
                    className={iconFillClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.delta:
            return <Delta className={iconFillClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.document:
            return <Document className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.doubleCloud:
            return (
                <DoubleCloud className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.doubleLink:
            return (
                <DoubleLink className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.drag:
            return <Drag className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.dragDrop:
            return <DragDrop className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.elevator:
            return <Elevator className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.entryBoard:
            return (
                <EntryBoard className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.globe:
            return <Globe className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.grid:
            return <Grid className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.layout:
            return <Layout className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.like:
            return <Like className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.list:
            return <List className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.reset:
            return <Reset className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.settings:
            return <Settings className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.search:
            return <Search className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.record:
            return <Record className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.recordSolid:
            return (
                <RecordSolid
                    className={iconFillClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.sortDown:
            return <SortDown className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.members:
            return <Members className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.info:
            return <Info className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.plus:
            return <Plus className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.minus:
            return <Minus className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.team:
            return <Team className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.trash:
            return <Trash className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.textTool:
            return <TextTool className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.multipleChoice:
            return (
                <MultipleChoice
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.number:
            return <Number className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.orchestration:
            return (
                <Orchestration
                    className={iconFillClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.dollarSign:
            return (
                <DollarSign className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.paragraph:
            return <Paragraph className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.percent:
            return <Percent className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.perspectiveBoard:
            return (
                <PerspectiveBoard
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.presenter:
            return <Presenter className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;

        case iconNames.singleChoice:
            return (
                <SingleChoice className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.spreadsheet:
            return (
                <Spreadsheet className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.thinking:
            return <Thinking className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.workflow:
            return <Workflow className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.pointer:
            return <Pointer className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.move:
            return <Move className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.lineage:
            return <Lineage className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.folder:
            return <Folder className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.folderPlus:
            return (
                <FolderPlus className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.folderSolid:
            return (
                <FolderSolid
                    className={iconFillClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.link:
            return <LinkIcon className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.multiArrowRight:
            return (
                <MultiArrowRightIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.mindMap:
            return (
                <MindMapIcon className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.sidebarExpanded:
            return (
                <SidebarExpandedIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.sidebarContracted:
            return (
                <SidebarContractedIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        case iconNames.filter:
            return (
                <FilterIcon className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.user:
            return <User className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.writer:
            return <Writer className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />;
        case iconNames.workbinBoard:
            return (
                <WorkbinBoard className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.workflowBoard:
            return (
                <WorkflowBoard className={iconClassNames} style={sizingStyle} viewBox="0 0 24 24" />
            );
        case iconNames.workspaces:
            return (
                <WorkspacesIcon
                    className={iconClassNames}
                    style={sizingStyle}
                    viewBox="0 0 24 24"
                />
            );
        default:
            throw new Error(`Unrecognized icon: ${name}`);
    }
}
Icon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.oneOf(Object.values(textColorNames)),
    name: PropTypes.oneOf(Object.values(iconNames)),
};
